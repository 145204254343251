<template>
  <div class="compare-diseases-control-home">
    <diseases-control-header
      :is-comparison-screen="isComparisonScreen"
      :title="$t('DiseasesControl.header.compare_programs')"
      @filter-reset="resetFilters"
      @set-field="setFilteredField"
      @set-period="setFilteredPeriod"
      @set-variety="setFilteredVariety"
    />
    <div class="df-flex-l home__content">
      <v-btn
        class="left-scroll-button"
        color="#39AF49"
        fab
        height="40"
        width="40"
        @click="scrollContainer('right')"
      >
        <font-awesome-icon class="mr-1" color="#fff" icon="chevron-left" />
      </v-btn>
      <v-btn
        class="right-scroll-button"
        color="#39AF49"
        fab
        height="40"
        width="40"
        @click="scrollContainer('left')"
      >
        <font-awesome-icon color="#fff" icon="chevron-right" />
      </v-btn>
      <section
        v-if="
          loadingProgramAddition ||
          loadingFilteredPrograms ||
          addedPrograms.length
        "
        class="df-flex-l content__programs"
      >
        <div v-if="loadingFilteredPrograms" class="df-flex-l loading-programs">
          <div v-for="(_, index) in 2" :key="index">
            <v-skeleton-loader
              class="loading-top"
              height="200px"
              type="image"
              width="276px"
            />
            <v-skeleton-loader
              class="loading-bottom"
              height="57px"
              type="image"
              width="276px"
            />
            <v-skeleton-loader
              v-if="showTallerPlaceholder(index)"
              height="119px"
              type="image"
              width="276px"
            />
          </div>
        </div>
        <div v-else class="df-flex-l programs">
          <program-item
            v-for="addedProgram in addedPrograms"
            :key="addedProgram.id"
            :crop-name="addedProgram.crop_name.split('.')[1]"
            :is-comparison-screen="isComparisonScreen"
            :program="addedProgram"
          >
            <template #farm>
              <div class="df-flex-sm">
                <span class="flex-grow-1 program-farm">
                  {{ addedProgram.farm }}
                </span>
                <v-btn icon small @click="removeProgram(addedProgram.id)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </program-item>
        </div>
        <div v-if="loadingProgramAddition">
          <v-skeleton-loader
            class="loading-top"
            height="200px"
            type="image"
            width="276px"
          />
          <v-skeleton-loader
            class="loading-bottom"
            height="57px"
            type="image"
            width="276px"
          />
          <v-skeleton-loader height="119px" type="image" width="276px" />
        </div>
      </section>
      <section class="df-flex-xm df-flex-col content__program-adder">
        <df-filter-select
          v-model="farm"
          search-bar
          width="276"
          :items="formattedFarms"
          :loading="loadingFarms"
          :placeholder="$t('DiseasesControl.compare_home.farm')"
          :search-placeholder="$t('DiseasesControl.compare_home.search_farm')"
          @click="fetchVarieties"
        />
        <df-filter-select
          v-model="variety"
          search-bar
          width="276"
          :disabled="isVarietySelectorDisabled"
          :items="varieties"
          :loading="loadingVarieties"
          :placeholder="$t('DiseasesControl.compare_home.variety')"
          :search-placeholder="
            $t('DiseasesControl.compare_home.search_variety')
          "
          @click="fetchPrograms"
        />
        <df-filter-select
          v-model="program"
          search-bar
          width="276"
          :disabled="isProgramSelectorDisabled"
          :items="formattedPrograms"
          :loading="loadingPrograms"
          :placeholder="$t('DiseasesControl.compare_home.program')"
          :search-placeholder="
            $t('DiseasesControl.compare_home.search_program')
          "
        />
        <df-button :disabled="isAddProgramDisabled" @click="addProgram">
          {{ $t('DiseasesControl.compare_home.add') }}
        </df-button>
      </section>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfFilterSelect from '@/lib/components/FilterSelect/DfFilterSelect.vue'
import DiseasesControlHeader from '@/modules/diseasesControl/components/DiseasesControlHeader.vue'
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService.js'
import LayoutMixin from '@/components/LayoutMixin.vue'
import ProgramItem from '@/modules/diseasesControl/components/list/ProgramItem.vue'
import { mapActions } from 'vuex'

export default {
  name: 'CompareDiseasesControlHome',

  mixins: [LayoutMixin],

  components: {
    DfButton,
    DfFilterSelect,
    DiseasesControlHeader,
    ProgramItem,
  },

  data() {
    return {
      addedPrograms: [],
      containerContentWidth: 0,
      currentScrolledScreen: 0,
      farm: {},
      farmProgramObj: {},
      filteredFieldId: null,
      filteredPeriod: '',
      filteredVarietyId: null,
      formattedFarms: [],
      formattedPrograms: [],
      loadingFarms: false,
      loadingFilteredPrograms: false,
      loadingPrograms: false,
      loadingProgramAddition: false,
      loadingVarieties: false,
      program: {},
      programIds: [],
      variety: {},
      varieties: [],
    }
  },

  async created() {
    await Promise.allSettled([this.fetchDiseases(), this.fetchProgramFarms()])
  },

  mounted() {
    window.addEventListener('resize', this.checkOverflow)
  },

  computed: {
    isAddProgramDisabled() {
      return !this.program.value
    },
    isComparisonScreen() {
      return this.$route.path === '/diseases_control/compare'
    },
    isProgramSelectorDisabled() {
      return !this.variety.value
    },
    isVarietySelectorDisabled() {
      return !this.farm.value
    },
  },

  methods: {
    ...mapActions('seasonsCrops', ['fetchDiseases']),
    async addProgram() {
      this.loadingProgramAddition = true
      const programId = Number(this.program.value)
      this.programIds.push(programId)
      this.farmProgramObj[programId] = this.farm.name
      await this.getFilteredPrograms()
      this.loadingProgramAddition = false
    },
    checkOverflow() {
      const programAdderWidth = 276
      const programCardWidth = 276
      const gap = 16
      this.containerContentWidth = this.addedPrograms.length * programCardWidth
      this.containerContentWidth +=
        gap * (this.addedPrograms.length - 1) + programAdderWidth
      const scrollButton = document.querySelector('.right-scroll-button')
      const maxInnerWidth = 1440
      let innerWidth = window.innerWidth

      if (!this.isMobile) {
        const drawerWidth = 56
        innerWidth -= drawerWidth
      }

      const isOverflowing =
        this.containerContentWidth > Math.min(maxInnerWidth, innerWidth)

      if (isOverflowing) {
        scrollButton?.classList.add('show')
      } else {
        scrollButton?.classList.remove('show')
      }
    },
    async fetchProgramFarms() {
      this.loadingFarms = true
      try {
        const { data } = await diseasesControlService.getProgramFarms()
        this.formattedFarms = data.map((farm) => {
          return {
            name: farm.name,
            value: String(farm.id),
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingFarms = false
      }
    },
    async fetchPrograms() {
      this.loadingPrograms = true
      const params = {
        farm_id: Number(this.farm.value),
        variety_id: Number(this.variety.value),
      }
      try {
        const { data } = await diseasesControlService.getProgramsList(params)
        this.formattedPrograms = data.map((program) => {
          return {
            name: program.name,
            value: String(program.id),
          }
        })
        this.filterFormattedProgramsList()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingPrograms = false
      }
    },
    async fetchVarieties() {
      this.loadingVarieties = true
      const params = {
        farm_id: this.farm.value,
      }
      try {
        const { data } = await diseasesControlService.getProgramVarieties(
          params
        )
        const varietyIds = []
        this.varieties = data.reduce((acc, variety) => {
          if (!varietyIds.includes(variety.id)) {
            acc.push({
              name: `${this.$t(
                `SowingsPlanning.variety_list_item.${variety.crop_message_key}`
              )} | ${variety.name}`,
              value: String(variety.id),
            })
            varietyIds.push(variety.id)
          }
          return acc
        }, [])
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingVarieties = false
      }
    },
    filterFormattedProgramsList() {
      const addedProgramIds = this.addedPrograms.reduce((acc, program) => {
        acc.push(String(program.id))
        return acc
      }, [])
      this.formattedPrograms = this.formattedPrograms.filter(
        (program) => !addedProgramIds.includes(program.value)
      )
    },
    async filterPrograms() {
      if (!this.programIds.length) return
      this.loadingFilteredPrograms = true
      await this.getFilteredPrograms()
      this.loadingFilteredPrograms = false
    },
    async getFilteredPrograms() {
      const params = {
        field_id: this.filteredFieldId,
        period: this.filteredPeriod,
        program_ids: this.programIds,
        variety_id: this.filteredVarietyId,
      }
      try {
        const { data } = await diseasesControlService.getPrograms(params)
        this.addedPrograms = data.map((program) => {
          return { ...program, farm: this.farmProgramObj[program.id] }
        })
        this.filterFormattedProgramsList()
        this.program = {}
      } catch (error) {
        console.error(error)
      } finally {
        this.checkOverflow()
      }
    },
    removeProgram(programId) {
      this.addedPrograms = this.addedPrograms.filter(
        (program) => program.id != programId
      )
      this.programIds = this.programIds.filter((id) => id != programId)
      this.variety = {}
      this.checkOverflow()
    },
    resetFilters() {
      this.filteredFieldId = null
      this.filteredPeriod = ''
      this.filteredVarietyId = null
      this.filterPrograms()
    },
    scrollContainer(side) {
      const container = document.querySelector('.home__content')
      const containerSize = container.offsetWidth
      const cardWidth = 276
      const scrollDirection = side === 'right' ? -1 : 1
      const gap = 12
      const scrollSize =
        scrollDirection *
        ((containerSize * 2) / 3 < cardWidth + gap
          ? cardWidth + gap
          : (containerSize * 2) / 3)

      container.scrollBy({
        left: scrollSize,
        behavior: 'smooth',
      })

      this.currentScrolledScreen += scrollSize
      const leftScrollButton = document.querySelector('.left-scroll-button')
      const rightScrollButton = document.querySelector('.right-scroll-button')

      if (side === 'left') {
        if (!leftScrollButton.classList.contains('show'))
          leftScrollButton.classList.add('show')
        if (
          containerSize + this.currentScrolledScreen >=
          this.containerContentWidth
        )
          rightScrollButton.classList.remove('show')
      } else {
        if (!rightScrollButton.classList.contains('show'))
          rightScrollButton.classList.add('show')
        if (this.currentScrolledScreen <= 0)
          leftScrollButton.classList.remove('show')
      }
    },
    setFilteredField(field) {
      this.filteredFieldId = Number(field.value)
      this.filterPrograms()
    },
    setFilteredPeriod(period) {
      this.filteredPeriod = period
      this.filterPrograms()
    },
    setFilteredVariety(variety) {
      this.filteredVarietyId = Number(variety.value)
      this.filterPrograms()
    },
    showTallerPlaceholder(index) {
      return index % 2 == 0
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow)
  },
}
</script>

<style lang="scss" scoped>
.compare-diseases-control-home {
  .home__content {
    max-width: 1472px;
    padding: 24px 16px 24px 16px;
    margin: 0px auto;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;

    @include d(m) {
      padding: 16px 8px 16px 8px;
    }
    .content__programs,
    .loading-programs,
    .programs {
      flex-grow: 0;
      flex-wrap: nowrap;
    }
    .program-farm {
      padding: 4px 12px;
      background-color: #f8f9f8;
      border-radius: 4px;
      overflow: hidden;
      color: #1a2b46;
      text-overflow: ellipsis;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    .content__program-adder {
      max-width: 276px;
    }
    .loading-top {
      border-radius: 4px 4px 0px 0px !important;
    }
    .loading-bottom {
      border-radius: 0px 0px 4px 4px !important;
    }
  }
}
.left-scroll-button {
  display: none;
  position: fixed;
  top: 50%;
  left: 86px;
  z-index: 2;

  @include d(m) {
    left: 10px;
  }
}
.right-scroll-button {
  display: none;
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 2;

  @include d(m) {
    right: 10px;
  }
}
.left-scroll-button.show,
.right-scroll-button.show {
  display: block;
}
</style>
