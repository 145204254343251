<template>
  <div>
    <diseases-control-header
      :title="$t('DiseasesControl.header.planning_title')"
      @list-items="requestPrograms"
      @set-field="setField"
      @set-fields="setFields"
      @set-period="setPeriod"
      @set-variety="setVariety"
      @set-varieties="setVarieties"
    />
    <div class="df-flex-l df-flex-col diseases-control-home">
      <section>
        <div v-if="loadingContent" class="df-flex-m home__list-programs">
          <div v-for="(_, index) in programs.length || 5" :key="index">
            <v-skeleton-loader
              class="placeholder"
              type="image"
              width="276px"
              :height="showTallerPlaceholder(index) ? '202px' : '176px'"
            />
            <v-skeleton-loader
              v-if="showTallerPlaceholder(index)"
              class="placeholder"
              height="132px"
              type="image"
              width="276px"
            />
          </div>
        </div>
        <without-item-card
          v-else-if="!loadingContent && !fields.length"
          name="add-field"
          text="DiseasesControl.home.without_field_text"
          title="DiseasesControl.home.without_field_title"
        />
        <without-item-card
          v-else-if="!loadingContent && !varieties.length"
          name="add-sowing"
          text="DiseasesControl.home.without_sowing_text"
          title="DiseasesControl.home.without_sowing_title"
        />
        <without-item-card
          v-else-if="!loadingContent && !programs.length"
          name="add-program"
          text="DiseasesControl.home.without_program_text"
          title="DiseasesControl.home.without_program_title"
        />
        <div v-else class="df-flex-m home__list-programs">
          <v-btn
            class="left-scroll-button"
            color="#39AF49"
            fab
            height="40"
            width="40"
            @click="scrollContainer('right')"
          >
            <font-awesome-icon class="mr-1" color="#fff" icon="chevron-left" />
          </v-btn>
          <v-btn
            class="right-scroll-button"
            color="#39AF49"
            fab
            height="40"
            width="40"
            @click="scrollContainer('left')"
          >
            <font-awesome-icon color="#fff" icon="chevron-right" />
          </v-btn>
          <program-item
            v-for="program in programs"
            :key="program.id"
            :crop-name="cropName"
            :field-id="Number(field.value)"
            :program="program"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DiseasesControlHeader from '@/modules/diseasesControl/components/DiseasesControlHeader.vue'
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService.js'
import LayoutMixin from '@/components/LayoutMixin.vue'
import ProgramItem from '@/modules/diseasesControl/components/list/ProgramItem.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { logEvent, events } from '@/services/analytics'
import { mapActions } from 'vuex'
import { crops, translateCropName } from '@/utils/crops'

export default {
  name: 'DiseasesControlHome',

  mixins: [LayoutMixin],

  components: {
    DiseasesControlHeader,
    ProgramItem,
    WithoutItemCard,
  },

  data() {
    return {
      containerContentWidth: 0,
      cropName: '',
      currentScrolledScreen: 0,
      field: {},
      fields: [],
      loadingContent: true,
      period: '',
      programs: [],
      variety: {},
      varieties: [],
    }
  },

  provide() {
    return {
      getFields: this.getFields,
      getVarietyId: this.getVarietyId,
      requestPrograms: this.requestPrograms,
    }
  },

  async created() {
    logEvent(events.sowingsListModule.SowingsList.visited_page)
    await this.fetchDiseases()
    this.$root.$on('request-program-data', this.sendData)
  },

  mounted() {
    window.addEventListener('resize', this.checkOverflow)
  },

  methods: {
    ...mapActions('seasonsCrops', ['fetchDiseases']),
    checkOverflow() {
      const programCardWidth = 276
      const gap = 12
      this.containerContentWidth = this.programs.length * programCardWidth
      this.containerContentWidth += gap * (this.programs.length - 1)
      const scrollButton = document.querySelector('.right-scroll-button')
      const maxInnerWidth = 1440
      let innerWidth = window.innerWidth

      if (!this.isMobile) {
        const drawerWidth = 56
        innerWidth -= drawerWidth
      }

      const isOverflowing =
        this.containerContentWidth > Math.min(maxInnerWidth, innerWidth)

      if (isOverflowing) {
        scrollButton?.classList.add('show')
      } else {
        scrollButton?.classList.remove('show')
      }
    },
    getFields() {
      return this.fields
    },
    getVarietyId() {
      return Number(this.variety.value)
    },
    async requestPrograms() {
      this.loadingContent = true
      this.setCrop()
      const params = {
        field_id: this.field.value,
        variety_id: this.variety.value,
        period: this.period,
      }
      try {
        const { data } = await diseasesControlService.getPrograms(params)
        this.programs = data
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingContent = false
        this.$nextTick(() => {
          this.checkOverflow()
        })
      }
    },
    scrollContainer(side) {
      const container = document.querySelector('.home__list-programs')
      const containerSize = container.offsetWidth
      const cardWidth = 276
      const scrollDirection = side === 'right' ? -1 : 1
      const gap = 12
      const scrollSize =
        scrollDirection *
        ((containerSize * 2) / 3 < cardWidth + gap
          ? cardWidth + gap
          : (containerSize * 2) / 3)

      container.scrollBy({
        left: scrollSize,
        behavior: 'smooth',
      })

      this.currentScrolledScreen += scrollSize
      const leftScrollButton = document.querySelector('.left-scroll-button')
      const rightScrollButton = document.querySelector('.right-scroll-button')

      if (side === 'left') {
        if (!leftScrollButton.classList.contains('show'))
          leftScrollButton.classList.add('show')
        if (
          containerSize + this.currentScrolledScreen >=
          this.containerContentWidth
        )
          rightScrollButton.classList.remove('show')
      } else {
        if (!rightScrollButton.classList.contains('show'))
          rightScrollButton.classList.add('show')
        if (this.currentScrolledScreen <= 0)
          leftScrollButton.classList.remove('show')
      }
    },
    sendData() {
      const cropMessageKey = 'crop.' + this.cropName
      const params = {
        crop_id: crops.find((crop) => crop.name === cropMessageKey)?.id,
        crop_message_key: cropMessageKey,
        fields: this.fields.map((field) => {
          return {
            name: field.name,
            value: String(field.id),
          }
        }),
        filtered_field: this.field,
        filtered_variety: this.variety,
        varieties: this.varieties,
      }
      this.$root.$emit('diseases-control-params', params)
    },
    setCrop() {
      const cropInCurrentLanguage = this.variety.name
        .split(' | ')[0]
        .toLowerCase()
      this.cropName = translateCropName(cropInCurrentLanguage)
    },
    setField(field) {
      this.field = field
    },
    setFields(fields) {
      if (!fields.length) this.loadingContent = false
      this.fields = fields.map((field) => {
        return {
          id: Number(field.value),
          name: field.name,
        }
      })
    },
    setPeriod(period) {
      this.period = period
    },
    setVariety(variety) {
      this.variety = variety
    },
    setVarieties(varieties) {
      if (!varieties.length) this.loadingContent = false
      this.varieties = varieties
    },
    showTallerPlaceholder(index) {
      return index % 2 == 0
    },
  },

  beforeDestroy() {
    this.$root.$off('request-program-data', this.sendData)
    window.removeEventListener('resize', this.checkOverflow)
  },
}
</script>

<style lang="scss" scoped>
.diseases-control-home {
  max-width: 1472px;
  padding: 24px 16px 24px 16px;
  margin: 0px auto;

  @include d(m) {
    padding: 16px 8px 16px 8px;
  }
  .home__list-programs {
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;

    .placeholder {
      flex-shrink: 0;
    }
  }
}
.left-scroll-button {
  display: none;
  position: fixed;
  top: 50%;
  left: 86px;
  z-index: 2;

  @include d(m) {
    left: 10px;
  }
}
.right-scroll-button {
  display: none;
  position: fixed;
  top: 50%;
  right: 30px;
  z-index: 2;

  @include d(m) {
    right: 10px;
  }
}
.left-scroll-button.show,
.right-scroll-button.show {
  display: block;
}
</style>
